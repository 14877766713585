<template>
    <div class="sign-up-form auth-form">
        <h1>Sign Up</h1>
        <ValidationObserver v-slot="{ valid, handleSubmit }" slim>
            <form novalidate @submit.prevent="handleSubmit(signUp)">
                <div class="row">
                    <div class="col">
                        <ValidationProvider
                            v-slot="{ errors }"
                            mode="lazy"
                            name="email"
                            rules="required|email"
                        >
                            <div :class="{ 'required' : errors.length }" class="form-group">
                                <label for="email">Email <span v-if="errors.length">({{ errors[0] }})</span></label>
                                <input
                                    id="email"
                                    v-model="email"
                                    type="text"
                                    class="form-control"
                                    placeholder="Enter your email"
                                >
                            </div>
                        </ValidationProvider>
                        <ValidationProvider
                            v-slot="{ errors }"
                            mode="lazy"
                            name="password"
                            rules="required|password:@password"
                        >
                            <div :class="{ 'required' : errors.length }" class="form-group">
                                <label for="password">Password <span v-if="errors.length">({{ errors[0] }})</span></label>
                                <input
                                    id="password"
                                    v-model="password"
                                    type="password"
                                    class="form-control"
                                    placeholder="Enter your password"
                                >
                            </div>
                        </ValidationProvider>
                        <ValidationProvider
                            v-slot="{ errors }"
                            name="confirm password"
                            :mode="validateAfterDebounce"
                            rules="required|passwordConfirm:@password"
                        >
                            <div :class="{ 'required' : errors.length }" class="form-group">
                                <label for="confirmPassword">Confirm password <span v-if="errors.length">({{ errors[0] }})</span></label>
                                <input
                                    id="confirmPassword"
                                    v-model="confirmPassword"
                                    type="password"
                                    class="form-control"
                                    placeholder="Enter your confirm password"
                                >
                            </div>
                        </ValidationProvider>
                        <div class="disclaimer">
                            By clicking Sign Up, you agree to our
                            <router-link :to="{ name: 'memod-tos' }" target="_blank">
                                Terms of Service
                            </router-link>
                            ,
                            <router-link :to="{ name: 'memod-privacy-policy' }" target="_blank">
                                Privacy Policies
                            </router-link>
                            and
                            <router-link :to="{ name: 'memod-end-user-license-agreement' }" target="_blank">
                                End-User License Agreement
                            </router-link>
                        </div>
                        <button :disabled="!valid" class="btn btn-primary btn-block">
                            Sign Up
                        </button>
                        <router-link class="forgot-password-link" :to="{ name: 'forgot-password' }">
                            Forgot your password?
                        </router-link>
                    </div>
                </div>
            </form>
            <social-auth :process-auth="processSignUp" />
            <span class="change-component">
                Already have account? <a href="javascript:void(0)" @click="$emit('set-auth-form', 'sign-in-form')">Sign In</a>
            </span>
        </validationObserver>
    </div>
</template>

<script>
import { extend } from "vee-validate";
import { setTokens } from "@/utils";

extend("passwordConfirm", {
    params: ["target"],
    validate(value, { target }) {
        return value === target;
    },
    message: "Password confirmation does not match"
});

extend("password", {
    params: ["target"],
    validate: value => {
        return value.length >= 8
    },
    message: "Password must be at least 8 characters long"
});

export default {
    name: "SignUpForm",
    components: {
        SocialAuth: () => import(/* webpackChunkName: "sign-in-form"*/ "./social-auth")
    },
    data() {
        return {
            email: "",
            password: "",
            confirmPassword: "",
            isLoading: false
        };
    },
    watch: {
        isLoading() {
            this.$emit("is-loading", this.isLoading);
        }
    },
    methods: {
        validateAfterDebounce() {
            return {
                on: ["input"],
                debounce: 250
            };
        },
        processSignUp(data) {
            this.$emit("auth-sucessfull", data);
            this.$store.dispatch("User/setToken", data.token);

            setTokens(data);
        },
        signUp() {
            const userData = {
                email: this.email,
                password: this.password,
                verify_password: this.confirmPassword
            }

            this.isLoading = true;

            axiosPublic.post(`/users`, userData).then(({ data }) => {

                this.processSignUp(data.session);
                this.isLoading = false;

            }).catch((error) => {
                this.isLoading = false;

                this.$notify({
                    group: "auth-notifications",
                    type: "error",
                    text: error.response.data.errors.message,
                    duration: 3000
                });
                console.log(error.response.data.errors.message)
            }).finally(() => {
                // this.$emit("is-loading", false);
            });
        }
    }
}
</script>

<style lang="scss" scoped>
form {
    /* padding-bottom: 0px !important; */

    .form-group {
        margin-bottom: 24px;
    }

    .disclaimer {
        text-align: center;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        padding-bottom: 16px;

        a {
            color: $primary-color;
        }
    }
}
</style>